import React from "react";
import price from "../../../assets/images/icons/price.png";
import plant from "../../../assets/images/icons/plant.png";
import guarantee from "../../../assets/images/icons/guarantee.png";
import rating from "../../../assets/images/icons/rating.png";
import team from "../../../assets/images/icons/team.png";

const SectionKenapaHarusRangers = () => {
  let data = [
    {
      image: price,
      label: "Mendapatkan Penghasilan",
    },
    {
      image: plant,
      label: "Mendapatkan Kesempatan Beasiswa",
    },
    {
      image: guarantee,
      label: "Mendapatkan Pelatihan",
    },
    {
      image: rating,
      label: "Dapatkan Relasi dengan CS Rangers & VS Customers se-Jabodetabek",
    },
    {
      image: team,
      label: "Ratusan Mitra Sudah Bergabung",
    },
  ];

  return (
    <section id="kenapa-harus-rangers">
      <article className="max-w-container mx-auto px-6 md:px-4 flex flex-col gap-8 py-16">
        <h1 className="montserrat not-italic font-bold text-3xl leading-[140%] tracking-[0.005em] text-dark">
          Kenapa Harus Bergabung Dengan{" "}
          <span className="text-primary">Cleansheet</span> Rangers
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-10 gap-y-4">
          {data.map((item, index) => (
            <div
              className="col-span-1 flex flex-row items-center gap-4"
              key={index}
            >
              <img src={item.image} alt={index} className="w-12 h-12" />
              <div className="not-italic font-bold text-base leading-[140%] tracking-[0.005em] text-dark">
                {item.label}
              </div>
            </div>
          ))}
        </div>
      </article>
    </section>
  );
};

export default SectionKenapaHarusRangers;
