import SectionLayanan from "../../../components/LandingPage/ServicesSection/OutsourcingService/SectionLayanan";
import TabTitle from "../../../utils/TabTitle";

const OutsourcingPage = () => {
    TabTitle("Outsourcing Management - GStep Indonesia");
    return (
        <>
            <SectionLayanan />
        </>
    );
}

export default OutsourcingPage;