import {mdiArrowRight} from "@mdi/js";
import Icon from "@mdi/react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import mainCommissioner from "../../../assets/images/teams/main_commissioner.png";
import commissioner from "../../../assets/images/teams/commissioner.png";
import ceo from "../../../assets/images/teams/ceo.png";
import advisor from "../../../assets/images/teams/advisor.png";
import operation from "../../../assets/images/teams/operation.png";
import hrm from "../../../assets/images/teams/hrm.png";
import develop from "../../../assets/images/teams/development.png";
import React from "react";

function SectionTeam() {
  let team = [
    {
      photo: mainCommissioner,
      name: "Albert R. Soetanto",
      position: "Main Commissioner",
    },
    {
      photo: commissioner,
      name: "Dihqoon Nadaamist",
      position: "Commissioner",
    },
    {
      photo: ceo,
      name: "Agus Handoko Simangunsong",
      position: "CEO",
    },
    {
      photo: advisor,
      name: "Albertus Henny Hindarto",
      position: "Advisor",
    },
    {
      photo: operation,
      name: "Moh. Sirojul Abidin",
      position: "Head Of Operation",
    },
    {
      photo: hrm,
      name: "Seno Aji Wibowo",
      position: "Head HRM Operation",
    },
    {
      photo: develop,
      name: "Reza Taruna",
      position: "Head of Learning and Development operation",
    },
  ];
  return (
    <section
      id="tim-kami"
      className="relative w-full border-b-[1px] py-16 border-b-bright bg-light overflow-hidden"
    >
      <article className="max-w-container mx-auto px-6 md:px-4">
        <div>
          <div className="font-bold text-2xl flex  md:text-[32px] leading-[140%] tracking-wider montserrat justify-start md:justify-center">
            Our Management Team
          </div>
        </div>
      </article>
      <article>
        <Swiper
          breakpoints={{
            320: {
              spaceBetween: 8,
              centeredSlides: true,
              centeredSlidesBounds: false,
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
              slidesPerView: 1.1,
            },
            640: {
              spaceBetween: 10,
              centeredSlides: false,
              centeredSlidesBounds: false,
              slidesOffsetBefore: 16,
              slidesOffsetAfter: 16,
              slidesPerView: 2.1,
            },
            1024: {
              spaceBetween: 10,
              centeredSlides: false,
              centeredSlidesBounds: false,
              slidesOffsetBefore: 16,
              slidesOffsetAfter: 16,
              slidesPerView: 3.1,
            },
            1280: {
              spaceBetween: 15,
              centeredSlides: false,
              centeredSlidesBounds: false,
              slidesOffsetBefore: 100,
              slidesOffsetAfter: 100,
              slidesPerView: 5.1,
            },
            1920: {
              slidesOffsetBefore: 350,
              slidesOffsetAfter: 350,
              slidesPerView: 6.1,
            },
          }}
        >
          {team.map((item, index) => (
            <React.Fragment key={index}>
              <SwiperSlide>
                <div className="flex justify-center mt-[46px]">
                  <img
                    src={item.photo}
                    alt={item.name}
                    className="w-auto 2xl:w-[220px] h-[250px]"
                  />
                </div>
                <div className="flex justify-center text-center my-4 font-bold text-[22px] leading-[140%] tracking-wider">
                  {item.name}
                </div>
                <div className="flex justify-center font-normal text-default text-base leading-[140%] tracking-wider">
                  {item.position}
                </div>
              </SwiperSlide>
            </React.Fragment>
          ))}
        </Swiper>
      </article>
    </section>
  );
}

export default SectionTeam;
