import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import SectionLayanan from "../../../components/LandingPage/ServicesSection/HumanServiceProvider/SectionLayanan";
import TabTitle from "../../../utils/TabTitle";

const HumanServiceProviderPage = () => {
    TabTitle("Training - Gstep Indonesia");
    return (
        <>
            <SectionLayanan />
            <FloatingWhatsapp />
        </>
    );
}

export default HumanServiceProviderPage;