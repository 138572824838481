import React from "react";
import pesan1 from "../../../assets/images/illustrations/pesan1.png";
import pesan2 from "../../../assets/images/illustrations/pesan2.png";
import pesan3 from "../../../assets/images/illustrations/pesan3.png";
import pesan4 from "../../../assets/images/illustrations/pesan4.png";

const SectionHowToOrder = () => {
  return (
    <section id="how-to-order-independent">
      <article className="max-w-container mx-auto px-6 md:px-4 py-12">
        <div className="grid grid-cols-12 gap-2" id="langkah-1">
          <div className="hidden col-span-4 md:block md:col-span-3 py-7">
            <div className="cardTestimoni h-[155px] p-3 flex justify-center border-2 border-bright">
              <img src={pesan1} alt="Langkah 1" className="h-full" />
            </div>
          </div>
          <div className="col-span-12 md:col-span-9 flex flex-row gap-7 pl-0 md:pl-7">
            <div>
              <div className="h-full relative min-w-0 w-9">
                <div className="h-2/3 md:h-1/2 mx-auto w-0.5 bg-bright relative top-1/3 md:top-1/2"></div>
                <div className="absolute top-1/3 left-0 right-0 bottom-0 w-full flex justify-center">
                  <button
                    type="button"
                    className="w-9 h-9 rounded-full ring-2 ring-bright montserrat text-[20px] font-bold flex justify-center items-center bg-white text-dark"
                  >
                    1
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div className="flex flex-col items-start gap-3 pt-7">
                <div className="cardTestimoni h-[155px] p-3 flex md:hidden justify-center border-2 border-bright">
                  <img src={pesan1} alt="Langkah 1" className="h-full" />
                </div>
                <h4 className="montserrat not-italic font-bold leading-[34px] tracking-[0.005em] text-2xl">
                  Langkah 1: Masuk ke Halaman Pemesanan
                </h4>
                <p className="not-italic font-normal text-xl leading-[31px] tracking-[0.005em] text-default">
                  Download aplikasi Cleansheet melalui Playstore. Kemudian pilih
                  paket jasa yang kamu butuhkan.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-2" id="langkah-2">
          <div className="hidden col-span-4 md:block md:col-span-3 py-7">
            <div className="cardTestimoni h-[155px] p-3 flex justify-center border-2 border-bright">
              <img src={pesan2} alt="Langkah 2" className="h-full" />
            </div>
          </div>
          <div className="col-span-12 md:col-span-9 flex flex-row gap-7 pl-0 md:pl-7">
            <div>
              <div className="h-full relative min-w-0 w-9">
                <div className="h-full mx-auto w-0.5 bg-bright"></div>
                <div className="absolute top-1/3 left-0 right-0 bottom-0 w-full flex justify-center">
                  <button
                    type="button"
                    className="w-9 h-9 rounded-full ring-2 ring-bright montserrat text-[20px] font-bold flex justify-center items-center bg-white text-dark"
                  >
                    2
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div className="flex flex-col items-start gap-3 pt-7">
                <div className="cardTestimoni h-[155px] p-3 flex md:hidden justify-center border-2 border-bright">
                  <img src={pesan2} alt="Langkah 2" className="h-full" />
                </div>
                <h4 className="montserrat not-italic font-bold leading-[34px] tracking-[0.005em] text-2xl">
                  Langkah 2: Isi Formulir
                </h4>
                <p className="not-italic font-normal text-xl leading-[31px] tracking-[0.005em] text-default">
                  Isi data kalian dengan lengkap dan jelas agas Customer Service
                  kami dapat memahami tentang permohonan jasa serta data lokasi
                  detail yang telah anda inputkan melalui formulir yang sudah
                  kami sediakan.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-2" id="langkah-2">
          <div className="hidden col-span-4 md:block md:col-span-3 py-7">
            <div className="cardTestimoni h-[155px] p-3 flex justify-center border-2 border-bright">
              <img src={pesan3} alt="Langkah 3" className="h-full" />
            </div>
          </div>
          <div className="col-span-12 md:col-span-9 flex flex-row gap-7 pl-0 md:pl-7">
            <div>
              <div className="h-full relative min-w-0 w-9">
                <div className="h-full mx-auto w-0.5 bg-bright"></div>
                <div className="absolute top-1/3 left-0 right-0 bottom-0 w-full flex justify-center">
                  <button
                    type="button"
                    className="w-9 h-9 rounded-full ring-2 ring-bright montserrat text-[20px] font-bold flex justify-center items-center bg-white text-dark"
                  >
                    3
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div className="flex flex-col items-start gap-3 pt-7">
                <div className="cardTestimoni h-[155px] p-3 flex md:hidden justify-center border-2 border-bright">
                  <img src={pesan3} alt="Langkah 3" className="h-full" />
                </div>
                <h4 className="montserrat not-italic font-bold leading-[34px] tracking-[0.005em] text-2xl">
                  Langkah 3: Konfirmasi Pesanan
                </h4>
                <p className="not-italic font-normal text-xl leading-[31px] tracking-[0.005em] text-default">
                  Cek kembali jasa yang kamu pesan serta data lokasi yang akan
                  dibersihkan. Kemudian lakukan pembayaran.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-2" id="langkah-2">
          <div className="hidden col-span-4 md:block md:col-span-3 py-7">
            <div className="cardTestimoni h-[155px] p-3 flex justify-center border-2 border-bright">
              <img src={pesan4} alt="Langkah 4" className="h-full" />
            </div>
          </div>
          <div className="col-span-12 md:col-span-9 flex flex-row gap-7 pl-0 md:pl-7">
            <div>
              <div className="h-full relative min-w-0 w-9">
                <div className="h-1/2 mx-auto w-0.5 bg-bright"></div>
                <div className="absolute top-1/2 md:top-1/3 left-0 right-0 bottom-0 w-full flex justify-center">
                  <button
                    type="button"
                    className="w-9 h-9 rounded-full ring-2 ring-bright montserrat text-[20px] font-bold flex justify-center items-center bg-white text-dark"
                  >
                    4
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div className="flex flex-col items-start gap-3 pt-7">
                <div className="cardTestimoni h-[155px] p-3 flex md:hidden justify-center border-2 border-bright">
                  <img src={pesan4} alt="Langkah 4" className="h-full" />
                </div>
                <h4 className="montserrat not-italic font-bold leading-[34px] tracking-[0.005em] text-2xl">
                  Langkah 4: Mulai Bekerja
                </h4>
                <p className="not-italic font-normal text-xl leading-[31px] tracking-[0.005em] text-default">
                  Tim Cleansheet akan menuju lokasi yang telah kamu inputkan dan
                  langsung melakukan pembersihan sesuai jasa yang kamu pesan.
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export default SectionHowToOrder;
