import {
  mdiThumbUpOutline,
  mdiArrowLeftCircleOutline,
  mdiArrowRightCircleOutline,
  mdiArrowRight,
} from "@mdi/js";
import Icon from "@mdi/react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {Navigation, Pagination} from "swiper";
import {Link, NavLink} from "react-router-dom";
import Button from "../../Button";
import recruitment from "../../../assets/images/misc/recruitment.png";
import outsourcing from "../../../assets/images/misc/outsourcing.png";
import training from "../../../assets/images/misc/training.png";
import React from "react";

function SectionLayanan() {
  let layanan = [
    {
      image: recruitment,
      title: "Recruitment / Headhunting Service",
    },
    {
      image: outsourcing,
      title: "Outsourcing / Man Power Management",
    },
    {
      image: training,
      title: "Training & Development",
    },
  ];
  return (
    <section
      className="relative overflow-hidden py-16 bg-service-texture bg-cover"
      id="layanan-kami"
    >
      <article className="md:max-w-container mx-auto px-6 md:px-4">
        <div className="flex flex-col justify-start md:justify-center">
          <div className="font-bold flex flex-row flex-wrap justify-start md:justify-center text-2xl lg:text-[32px] leading-[140%] montserrat">
            Layanan&nbsp;
            <span>
              <span className="text-primary">GStep</span>&nbsp;Indonesia
            </span>
          </div>
        </div>
      </article>
      <aside className="mt-[46px] min-w-0 md:max-w-container mx-auto px-0 sm:px-4 grid grid-cols-1 md:grid-cols-12">
        <button
          className="hidden md:flex justify-start col-span-1 layanan-swiper-button-prev disabled:opacity-50"
          title="Navigate Previous Slide"
          aria-label="Navigate Previous Service Slide"
        >
          <Icon
            path={mdiArrowLeftCircleOutline}
            size={2}
            className="flex justify-center self-center text-white"
          />
        </button>
        <div className="col-span-1 md:col-span-10">
          <div className="w-full md:max-w-[1024px]">
            <Swiper
              modules={[Navigation, Pagination]}
              breakpoints={{
                320: {
                  slidesPerView: 1.1,
                  centeredSlides: true,
                },
                640: {
                  slidesPerView: 2,
                  centeredSlides: false,
                },
                1024: {
                  slidesPerView: 3,
                  centeredSlides: false,
                },
              }}
              spaceBetween={10}
              pagination={{
                el: ".layanan-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                  return '<span class="' + className + '"></span>';
                },
              }}
              navigation={{
                nextEl: ".layanan-swiper-button-next",
                prevEl: ".layanan-swiper-button-prev",
              }}
              loop={false}
            >
              {layanan.map((item, index) => (
                <React.Fragment key={index}>
                  <SwiperSlide>
                    <div className="rounded-[32px] p-6 h-full flex flex-col gap-4">
                      <div className="w-full h-[182.1px] rounded-2xl overflow-hidden">
                        <img
                          src={item.image}
                          alt={item.title}
                          className="object-cover w-full h-full" // Added w-full and h-full for center cropping
                        />
                      </div>
                      <div className="text-primaryDark montserrat not-italic font-bold text-2xl leading-[140%] tracking-[0.005em] text-center">
                        {item.title}
                      </div>
                      {/* Removed the "Lihat Detail" button and its container */}
                    </div>
                  </SwiperSlide>
                </React.Fragment>
              ))}
            </Swiper>
          </div>
        </div>
        <button
          className="hidden col-span-1 md:flex justify-end layanan-swiper-button-next disabled:opacity-50"
          title="Navigate Next Slide"
          aria-label="Navigate Next Service Slide"
        >
          <Icon
            path={mdiArrowRightCircleOutline}
            size={2}
            className="self-center text-white"
          />
        </button>
      </aside>
      <div
        className="mt-[58px] md:max-w-container mx-auto flex justify-center px-6 md:px-4"
        aria-label="service-navigation"
      >
        <div className="layanan-pagination block whitespace-nowrap"></div>
      </div>
      <div className="absolute h-1/2 bg-bright w-full bottom-0 -z-10"></div>
    </section>
  );
}

export default SectionLayanan;
