import { Link } from "react-router-dom";
import { ReactComponent as Phone } from "../../assets/vectors/icons/phone-icon.svg";
import { ReactComponent as Email } from "../../assets/vectors/icons/envelope-icon.svg"
import { ReactComponent as MapMarker } from "../../assets/vectors/icons/map-marker-icon.svg";

function Footer() {
  return (
    <div className="bg-dark lato text-white font-normal leading-[140%] tracking-wider text-sm">
      <div className="flex flex-col md:grid-cols-12 pt-16 gap-4 mx-auto">
        <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 px-6 lg:mx-16">
          <div className="md:flex justify-between">
            <nav
              aria-label="bottom-navigation"
              className="flex flex-col lg:flex gap-4 lg:gap-4 mb-6"
            >
              <div className="flex items-start text-base font-bold">Layanan</div>
              <Link to={"/services/training"}>
                <div className="flex items-start">Training</div>
              </Link>
              <Link to={"/services/recruitment-service"}
              >
                <div className="flex items-start">Recruitment Service</div>
              </Link>
              <Link to={"/services/outsourcing-service"}>
                <div className="flex items-start">Outsourcing/HR Management</div>
              </Link>
            </nav>
          </div>
          <div className="md:flex justify-between">
            <nav
              aria-label="bottom-navigation"
              className="flex flex-col lg:flex justify-between gap-4 lg:gap-4 mb-6"
            >
              <div className="flex items-start text-base font-bold">Hubungi Kami</div>
              <div className="flex flex-row grow items-center">
                <div>
                  <Phone className="self-center text-bright" />
                </div>
                <div className="text-white font-normal leading-[140%] tracking-wider text-sm self-center ml-2 lg:ml-[21.79px]">
                  <dt>+62 8593 5140 788</dt>
                </div>
              </div>
              <div className="flex flex-row grow items-center">
                <div>
                  <Email className="self-center text-bright" />
                </div>
                <div className="text-white font-normal leading-[140%] tracking-wider text-sm self-center ml-2 lg:ml-[21.79px]">
                  <dt>marketing@gstepindonesia.id</dt>
                </div>
              </div>
              <div className="flex flex-row items-center">
                <div>
                  <MapMarker className="self-center text-bright" />
                </div>
                <div className="text-white font-normal leading-[140%] tracking-wider text-sm self-center ml-2 lg:ml-[21.79px] ">
                  <dd>Head Office : </dd>
                  <dd>Startup Center IPB</dd>
                  <dt>
                    Jl. Taman Kencana No.3, Babakan,
                    Kec. Bogor Tengah, Kota Bogor,
                    Jawa Barat 16128
                  </dt>
                </div>
                <div>
                  <MapMarker className="self-center text-bright" />
                </div>
                <div className="text-white font-normal leading-[140%] tracking-wider text-sm self-center ml-2 lg:ml-[21.79px]">
                  <dd>Operational Office : </dd>
                  <dt>Jalan Puspa Sari, Blok W6
                    No.1i   Taman cimanggu
                    Kota Bogor – Jawa Barat
                  </dt>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div className="w-full py-6 items-center font-bold leading-[140%] tracking-wider text-center bg-primaryDark bg-about-texture">
          Hak Cipta © 2024 GSTEP Indonesia.
        </div>
      </div>
    </div>
  );
}

export default Footer;