import React from "react";
import cleansheet from "../../../assets/images/brands/cleansheet.png";
import arcadia from "../../../assets/images/brands/arcadia.png";
import ipb from "../../../assets/images/brands/ipb.png";
import studiorenang from "../../../assets/images/brands/studiorenang.png";
import ozlezzat from "../../../assets/images/brands/ozlezzat.jpeg";
import ipbhotel from "../../../assets/images/brands/ipb-hotel.png";
import larsen from "../../../assets/images/brands/larsen.png";
import pasi from "../../../assets/images/brands/pasi.png";
import pramoo from "../../../assets/images/brands/pramoo.png";
import brin from "../../../assets/images/brands/brin.png";

const SectionPartnerKlienKami = () => {
  return (
    <section id="klien-kami" className="py-16">
      <article className="max-w-container mx-auto px-6 md:px-4 flex flex-col gap-5">
        <div className="pb-8 flex flex-col gap-4">
          <div className="flex justify-start lg:justify-center sm:text-xl md:text-[22px] font-normal leading-[140%] tracking-wider">
            KLIEN KAMI
          </div>
          <div className="font-bold flex flex-wrap justify-start lg:justify-center text-2xl md:text-[32px] leading-[140%] montserrat whitespace-nowrap">
            Kami Sudah Melayani&nbsp;
            <span>
              <span className="text-primary">1000+</span>&nbsp;Pelanggan
            </span>
          </div>
        </div>
        <div className="flex flex-row flex-wrap justify-center gap-x-28 gap-y-16">
          <img
            src={cleansheet}
            alt="Cleansheet"
            className="h-16"
          />
          <img src={arcadia} alt="Arcadia" className="h-16" />
          <img src={ipb} alt="IPB University" className="h-16" />
          <img src={studiorenang} alt="Studio Renang" className="h-16" />
          <img src={ozlezzat} alt="OZ Lezzat" className="h-16" />
          <img src={ipbhotel} alt="IPB Convention Hotel" className="h-28" />
          <img src={larsen} alt="Larsen" className="h-16" />
          <img src={pasi} alt="Persatuan Atletik Seluruh Indonesia" className="h-20" />
          <img src={pramoo} alt="Pramoo" className="h-16 w-28" />
          <img src={brin} alt="Brin" className="h-16" />
        </div>
      </article>
    </section>
  );
};

export default SectionPartnerKlienKami;
