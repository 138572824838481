import SectionFoundation from "../../../components/LandingPage/AboutSection/SectionFoundation";
import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import TabTitle from "../../../utils/TabTitle";

const AboutPage = () => {
    TabTitle("Tentang - GStep");
    return (
        <>
        <SectionFoundation />
        <FloatingWhatsapp />
        </>
    );
}

export default AboutPage;