import React from "react";
import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import SectionVideoSliderPromo from "../../../components/LandingPage/HomeSection/SectionVideoSliderPromo";
import SectionBannerRangers from "../../../components/LandingPage/JoinUsSection/SectionBannerRangers";
import SectionCeritaRangers from "../../../components/LandingPage/JoinUsSection/SectionCeritaRangers";
import SectionKenapaHarusRangers from "../../../components/LandingPage/JoinUsSection/SectionKenapaHarusRangers";
import SectionTertarikBergabung from "../../../components/LandingPage/JoinUsSection/SectionTertarikBergabung";
import TabTitle from "../../../utils/TabTitle";

const Rangers = () => {
  TabTitle("Rangers - Cleansheet");
  return (
    <>
      <SectionBannerRangers />
      <SectionKenapaHarusRangers />
      <SectionCeritaRangers />
      <SectionVideoSliderPromo />
      <SectionTertarikBergabung
        type={"Rangers"}
        title={
          <>
            Tertarik Begabung Menjadi Rangers Biru{" "}
            <span className="text-primary">Cleansheet</span>?
          </>
        }
        description={
          "250+ Rangers Biru Sudah Bergabung! Saatnya Kamu Bergabung Menjadi CS Rangers Dan Dapatkan Manfaatnya Untuk Masa Depan Yang Lebih Baik"
        }
      />
      <FloatingWhatsapp />
    </>
  );
};

export default Rangers;
