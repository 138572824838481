import React from "react";
import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import SectionPartnerKlienKami from "../../../components/LandingPage/HomeSection/SectionPartnerKlienKami";
import SectionPartnerTestimoniPelanggan from "../../../components/LandingPage/HomeSection/SectionPartnerTestimoniPelanggan";
import TabTitle from "../../../utils/TabTitle";

const PartnerPage = () => {
  TabTitle("Partner - GStep");
  return (
    <>
      <SectionPartnerKlienKami />
      <SectionPartnerTestimoniPelanggan />
      <FloatingWhatsapp />
    </>
  );
};

export default PartnerPage;
