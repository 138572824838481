import news from '../../../assets/images/banner/news.png';

function SectionHeadline() {
    return (
        <section className="py-16" id="headline">
            <article className="relative max-w-container mx-auto px-6 md:px-4">
                <div className="grid grid-cols-2 gap-12">
                    <div>
                        <img src={news} alt="news" />
                        <div className="text-xs text-default mt-6 mb-2">26 Juli 2022</div>
                        <h2 className="mb-2 text-dark text-[32px] font-bold montserrat">Fogging Asap dan Embun, Mana yang Lebih Aman?</h2>
                        <p className="text-default ">Fogging bukan merupakan cara mencegah perkembangbiakan nyamuk disekitar kita, atau mensterilkan ruangan dengan desinfektan. Selain itu, fogging nyamuk hanya mematikan nyamuk dewasa saja, sedangkan telur dan jentik nyamuk, tidak akan mati dengan fogging.</p>
                    </div>
                    <div className="grid grid-rows-3">
                        <div className="grid grid-cols-2">
                            <div className="w-[258px] h-[178]"><img className="w-full object-cover" src={news} alt="news" /></div>
                            <div>
                                <div className="text-xs text-default">26 Juli 2022</div>
                                <div className="font-bold text-dark montserrat text-xl mt-1 mb-1">Fogging Asap dan Embun, Mana yang Lebih Aman?</div>
                                <div className="text-limit text-sm text-default">Fogging bukan merupakan cara mencegah perkembangbiakan nyamuk disekitar kita, atau mensterilkan ruangan dengan desinfektan. Selain itu, fogging nyamuk hanya mematikan nyamuk dewasa saja, sedangkan telur dan jentik nyamuk, tidak akan mati dengan fogging.</div>
                            </div>
                        </div>
                        <div className="grid grid-cols-2">
                            <div className="w-[258px] h-[178]"><img className="w-full object-cover" src={news} alt="news" /></div>
                            <div>
                                <div className="text-xs text-default">26 Juli 2022</div>
                                <div className="font-bold text-dark montserrat text-xl mt-1 mb-1">Fogging Asap dan Embun, Mana yang Lebih Aman?</div>
                                <div className="text-limit text-sm text-default">Fogging bukan merupakan cara mencegah perkembangbiakan nyamuk disekitar kita, atau mensterilkan ruangan dengan desinfektan. Selain itu, fogging nyamuk hanya mematikan nyamuk dewasa saja, sedangkan telur dan jentik nyamuk, tidak akan mati dengan fogging.</div>
                            </div>
                        </div>
                        <div className="grid grid-cols-2">
                            <div className="w-[258px] h-[178]"><img className="w-full object-cover" src={news} alt="news" /></div>
                            <div>
                                <div className="text-xs text-default">26 Juli 2022</div>
                                <div className="font-bold text-dark montserrat text-xl mt-1 mb-1">Fogging Asap dan Embun, Mana yang Lebih Aman?</div>
                                <div className="text-limit text-sm text-default">Fogging bukan merupakan cara mencegah perkembangbiakan nyamuk disekitar kita, atau mensterilkan ruangan dengan desinfektan. Selain itu, fogging nyamuk hanya mematikan nyamuk dewasa saja, sedangkan telur dan jentik nyamuk, tidak akan mati dengan fogging.</div>
                            </div>
                        </div>
                    </div>
                </div>

            </article>
        </section>

    );
}

export default SectionHeadline;