function SectionContactInfo() {
    return (
        <section className="py-16 relative" id="foundation">
            <article className="grid grid-cols-1 gap-12 max-w-container mx-auto px-6 md:px-4">
                <div className="col-span-3 lg:col-span-2 flex flex-col gap-5">
                    <div className="mb-6">
                        <div className="flex text-dark text-[16px]">
                            Kami lampirkan company profile dan katalog pelatihan sebagai bahan bacaan anda :
                        </div>
                        <div className="mt-5">
                            <ul className="list-inside text-dark mt-2">
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">

                                        <strong>Company Profile : </strong> &nbsp;
                                        <a href="https://bit.ly/Compro_GStep" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">https://bit.ly/Compro_GStep</a>
                                    </div>
                                </li>
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">

                                        <strong>Proposal Kegiatan : </strong> &nbsp;
                                        <a href="https://bit.ly/Proposal_Training_GStep" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">https://bit.ly/Proposal_Training_GStep</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="mt-5">
                            <div className="flex text-dark text-[16px]">
                                Mari bergandengan tangan untuk menciptakan dampak positif bersama! Hubungi kami untuk informasi
                                lebih lanjut:
                            </div>
                            <ul className="list-inside text-dark mt-2">
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">

                                        <strong>Telpon : </strong> &nbsp;
                                        <a href="https://wa.me/6285771912677" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">0857 7191 2677</a>
                                    </div>
                                </li>
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">

                                        <strong>Email : </strong> &nbsp;
                                        <a href="mailto:rezataruna@gstepindonesia.id" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">rezataruna@gstepindonesia.id</a>
                                    </div>
                                </li>

                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">

                                        <strong>Website : </strong> &nbsp;
                                        <a href="https:/gstepindonesia.id" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">www.gstepindonesia.id</a>
                                    </div>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </article>
        </section>

    );
}

export default SectionContactInfo;
