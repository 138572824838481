import React from "react";
import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import SectionAbout from "../../../components/LandingPage/HomeSection/SectionAbout";
import SectionBanner from "../../../components/LandingPage/HomeSection/SectionBanner";
import SectionBlog from "../../../components/LandingPage/HomeSection/SectionBlog";
import SectionKenapaKami from "../../../components/LandingPage/HomeSection/SectionKenapaKami";
import SectionOurClient from "../../../components/LandingPage/HomeSection/SectionOurClient";
import SectionInfo from "../../../components/LandingPage/HomeSection/SectionInfo";
import SectionLayanan from "../../../components/LandingPage/HomeSection/SectionLayanan";
import SectionGallery from "../../../components/LandingPage/HomeSection/SectionGallery";
import SectionPartnerTestimoniPelanggan from "../../../components/LandingPage/HomeSection/SectionPartnerTestimoniPelanggan";
import TabTitle from "../../../utils/TabTitle";
import SectionTeam from "../../../components/LandingPage/HomeSection/SectionTeam";
import SectionCleansheet from "../../../components/LandingPage/HomeSection/SectionCleansheet";
import SectionMedia from "../../../components/LandingPage/HomeSection/SectionMedia";

const HomePage = () => {
  TabTitle("Beranda - GStep");
  return (
    <>
      <SectionBanner />
      <SectionAbout />
      <SectionKenapaKami />
      <SectionLayanan />
      <SectionCleansheet />
      <SectionOurClient />
      <SectionTeam />
      <SectionPartnerTestimoniPelanggan />
      <SectionGallery />
      <FloatingWhatsapp />
    </>
  );
};

export default HomePage;
