import React from "react";
import testimoni from "../../../assets/images/misc/testimoni.png";
import Button from "../../Button";

const SectionBannerHeroes = () => {
  return (
    <section id="banner" className="bg-banner-texture bg-cover">
      <article className="max-w-container mx-auto px-6 md:px-4 grid grid-cols-12 gap-4 pt-16">
        <div className="col-span-12 md:col-span-5 flex flex-col gap-10 pb-16">
          <div className="flex flex-col gap-5">
            <h1 className="montserrat not-italic font-bold text-3xl leading-[140%] -tracking-[0.005em] text-dark">
              Dapatkan Penghasilan dan Jadilah{" "}
              <span className="text-primary">Pahlawan</span> Untuk Mereka Yang
              <span className="underlined whitespace-nowrap"> Membutuhkan</span>
            </h1>
            <p className="text-default not-italic font-normal text-base leading-[140%] tracking-[0.005em]">
              Menyelesaikan Masalah Kebersihan, Mengurangi Anak-Anak Putus
              Sekolah Serta Pengangguran Dan Ujungnya Pada Pengentasan
              Kemiskinan.
            </p>
          </div>
          <div>
            <Button customStyle="rounded-full">
              <div className="px-5 py-1 not-italic font-bold text-base leading-[140%] text-center tracking-[0.005em]">
                Daftar Heroes Sekarang
              </div>
            </Button>
          </div>
        </div>
        <aside className="col-span-12 md:col-end-13 md:col-span-6 flex items-end">
          <img src={testimoni} alt={"Rangers"} />
        </aside>
      </article>
    </section>
  );
};

export default SectionBannerHeroes;
