import React from "react";
import testimoni1 from "../../../assets/images/testimoni/testimoni-1.png";
import testimoni2 from "../../../assets/images/testimoni/testimoni-2.png";
import testimoni3 from "../../../assets/images/testimoni/testimoni-3.png";

const SectionPartnerTestimoniPelanggan = () => {
  let testimoni = [
    {
      image: "https://randomuser.me/api/portraits/men/45.jpg",
      name: "Faik Faruqi",
      role: "Enterpreneur",
      message:
        "GSTEP itu unggul dalam segala hal, fasilitasnya WOW!, attitude peserta programnya luar biasa, bahasa inggrisnya fasih, dan skillnya sudah teruji.",
    },
    {
      image: "https://randomuser.me/api/portraits/men/46.jpg",
      name: "M Rijal Karunia",
      role: "Barista",
      message:
        "Program-programnya sangat bagus untuk mengembangkan skill. Pokoknya recomended banget",
    },
    {
      image: "https://randomuser.me/api/portraits/men/49.jpg",
      name: "Ifa Alif",
      role: "Influencer",
      message:
        "GSTEP selalu berinovasi dan menjadi lebih baik dari waktu ke waktu",
    },
  ];
  return (
    <section
      id="cleansheet-partner-testimoni-pelanggan"
      className="py-16 relative"
    >
      <div className="bg-primaryDark h-1/4 md:h-1/3 lg:h-1/2 absolute top-0 left-0 right-0 -z-10"></div>
      <article className="max-w-container mx-auto px-6 md:px-4 flex flex-col gap-5">
        <div className="flex justify-center montserrat not-italic font-bold text-3xl leading-[140%] tracking-[0.005em] text-neutralLight">
          Testimoni
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {testimoni.map((testimoni, index) => (
            <div
              className="flex flex-col gap-4 col-span-1 items-center p-6 bg-white drop-shadow-bottom rounded-3xl"
              key={"testimoni" + index}
            >
              <div className="flex flex-col justify-center items-center p-0 gap-2">
                <img
                  src={testimoni.image}
                  alt={testimoni.name}
                  className="w-100 rounded-full"
                />
                <div className="flex flex-col justify-center items-center p-0">
                  <div className="not-italic font-bold text-base leading-[140%] tracking-[0.005em] text-dark">
                    {testimoni.name}
                  </div>
                  <div className="not-italic font-normal text-sm leading-5 tracking-[0.005em] text-default">
                    {testimoni.role}
                  </div>
                </div>
                <div>
                  <p className="not-italic font-normal text-base leading-[140%] tracking-[0.005em] text-default">
                    {testimoni.message}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </article>
    </section>
  );
};

export default SectionPartnerTestimoniPelanggan;
