import logo from '../../../assets/images/logo/logo_big.png'

function SectionFoundation() {
    return (
        <section className="py-16 relative" id="foundation">
            <article className="grid grid-cols-3 gap-12 max-w-container mx-auto px-6 md:px-4">
                <div className="col-span-3 lg:col-span-2 flex flex-col gap-5">
                    <h2 className="font-bold text-[32px] leading-[130%] tracking-wider text-dark">
                        <span className="underlined"><span className="text-primary">GStep</span>&nbsp;Indonesia</span>
                    </h2>
                    <div className="mb-6">
                        <div className="flex text-dark text-[16px]">
                            Tentang
                            Perusahaan Jasa pelayanan yang berfokus meningkatkan dan mengembangkan skill sumber daya manusia untuk semakin terlatih dan terpercaya menjadi bagian dari unit bisnis sesuai dengan perkembangan dinamika dunia industri
                        </div>
                        <div className="mt-5">
                            <h4 className="font-bold text-[24px] leading-[130%] tracking-wider text-dark">
                                <span className="underlined">Visi</span>
                            </h4>
                            <div className="text-dark text-[16px]">
                                Menjadi mitra terpercaya bagi perusahaan dan individu dalam membangun masa depan yang lebih baik melalui pengembangan sumber daya manusia, penyaluran dan pemberdayaan tenaga kerja yang berkualitas guna mendorong keberlanjutan di setiap aspek bisnis.
                            </div>
                        </div>
                        <div className="mt-5">
                            <h4 className="font-bold text-[24px] leading-[130%] tracking-wider text-dark">
                                <span className="underlined">Misi</span>
                            </h4>
                            <ul className="list-inside text-dark mt-2">
                                <li className="flex flex-col mb-2"> {/* Added margin-bottom for gap */}
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        <strong>Pengembangan Sumber Daya Manusia</strong>
                                    </div>
                                </li>
                                <li className="mb-2">
                                    <div className="flex items-center">
                                        Mendorong pengembangan sumber daya manusia melalui pelatihan berkelanjutan dan program pengembangan kepemimpinan yang mendukung pertumbuhan karir individu. kebutuhan industri, guna mempersiapkan sumber daya manusia yang siap bersaing di pasar.
                                    </div>
                                </li>
                                <li className="flex flex-col mb-2"> {/* Added margin-bottom for gap */}
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        <strong>Penyaluran dan Penyediaan Tenaga Kerja</strong>
                                    </div>
                                </li>
                                <li className="mb-2">
                                    <div className="flex items-center">
                                        Menyediakan layanan penyaluran tenaga kerja yang efektif dan efisien, memastikan kesesuaian antara keahlian tenaga kerja dengan kebutuhan perusahaan dengan tujuan meningkatkan produktivitas dan kebahagiaan kerja.
                                    </div>
                                </li>
                                <li className="flex flex-col mb-2"> {/* Added margin-bottom for gap */}
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        <strong>Konsultasi Manajemen dan CSR</strong>
                                    </div>
                                </li>
                                <li className="mb-2">
                                    <div className="flex items-center">
                                        Menawarkan layanan konsultasi yang berfokus pada peningkatan kinerja bisnis dan tanggung jawab sosial perusahaan, membantu klien mencapai tujuan keberlanjutan jangka panjang.
                                    </div>
                                </li>
                                <li className="flex flex-col mb-2"> {/* Added margin-bottom for gap */}
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        <strong>Kemitraan dan Kolaborasi</strong>
                                    </div>
                                </li>
                                <li className="mb-2">
                                    <div className="flex items-center">
                                        Membangun kemitraan strategis dengan berbagai pemangku kepentingan untuk memperluas jangkauan dan memberikan solusi yang efisien dan efektif guna mencapai tujuan berkelanjutan.
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-span-3 lg:col-span-1 flex justify-center lg:justify-end">
                    <div className="">
                        <img className="max-w-[300px]" src={logo} alt="" />
                    </div>
                    <div className="relative max-w-[584px] max-h-[410px] lg:w-[584px]">
                        {/* <img className="absolute bottom-0 w-full" src={hero} alt="" /> */}
                    </div>
                </div>
            </article>
        </section>

    );
}

export default SectionFoundation;