import React from "react";
import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import SectionHowToOrder from "../../../components/LandingPage/HowToOrderSection/SectionHowToOrder";
import SectionJasa from "../../../components/LandingPage/SectionJasa";
import TabTitle from "../../../utils/TabTitle";

const HowToOrderPage = () => {
  TabTitle("Cara Pesan - Cleansheeet");
  return (
    <>
      <SectionHowToOrder />
      <SectionJasa />
      <FloatingWhatsapp />
    </>
  );
};

export default HowToOrderPage;
