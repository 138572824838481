import React from "react";
import cerita1 from "../../../assets/images/testimoni/cerita-1.png";
import cerita2 from "../../../assets/images/testimoni/cerita-2.png";
import cerita3 from "../../../assets/images/testimoni/cerita-3.png";

const SectionCeritaRangers = () => {
  let cerita = [
    {
      image: cerita1,
      name: "Sanusi",
      role: "Rangers Biru Cleansheet",
      message:
        "Saya sangat senang saat mendapatkan beasiswa untuk melanjutkan pendidikan saya ke jenjang perkuliahan. Terlebih lagi orang tua turut berbahagia anaknya dapat melanjutkan pendidikannya untuk meraih kesuksesan",
    },
    {
      image: cerita2,
      name: "Fajri",
      role: "Rangers Biru Cleansheet",
      message:
        "Senang bisa dapat kesempatan kembali menuntut ilmu karena tidak ada kekayaan seperti pengetahuan, tidak ada kemiskinan seperti ketidaktahuan",
    },
    {
      image: cerita3,
      name: "Bagus",
      role: "Rangers Biru Cleansheet",
      message:
        "Cleansheet merupakan salah satu faktor yang berimpact pada karir saya untuk mewujudkan cita-cita saya menjadi PNS",
    },
  ];
  return (
    <section id="cleansheet-partner-cerita-rangers" className="py-16 relative">
      <div className="bg-primaryDark h-1/4 md:h-1/3 lg:h-1/2 absolute top-0 left-0 right-0 -z-10"></div>
      <article className="max-w-container mx-auto px-6 md:px-4 flex flex-col gap-5">
        <div className="flex montserrat not-italic font-bold text-3xl leading-[140%] tracking-[0.005em] text-neutralLight">
          Cerita Rangers Biru Cleansheet
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {cerita.map((cerita, index) => (
            <div
              className="flex flex-col gap-4 col-span-1 items-center p-6 bg-white drop-shadow-bottom rounded-3xl"
              key={"testimoni" + index}
            >
              <div className="flex flex-col justify-center items-center p-0 gap-2">
                <img src={cerita.image} alt={cerita.name} className="w-100" />
                <div className="flex flex-col justify-center items-center p-0">
                  <div className="not-italic font-bold text-base leading-[140%] tracking-[0.005em] text-dark">
                    {cerita.name}
                  </div>
                  <div className="not-italic font-normal text-sm leading-5 tracking-[0.005em] text-default">
                    {cerita.role}
                  </div>
                </div>
                <div>
                  <p className="not-italic font-normal text-base leading-[140%] tracking-[0.005em] text-default">
                    "{cerita.message}"
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </article>
    </section>
  );
};

export default SectionCeritaRangers;
