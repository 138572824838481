import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import SectionLayanan from "../../../components/LandingPage/ServicesSection/PublicTraining/SectionLayanan";
import TabTitle from "../../../utils/TabTitle";

const PublicTrainingPage = () => {
    TabTitle("Layanan Deep Cleaning Profesional - Cleansheet");
    return (
        <>
            <SectionLayanan />
            <FloatingWhatsapp />
        </>
    );
}

export default PublicTrainingPage;