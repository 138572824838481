import React from "react";
import cerita4 from "../../../assets/images/testimoni/cerita-4.png";
import cerita5 from "../../../assets/images/testimoni/cerita-5.png";
import cerita6 from "../../../assets/images/testimoni/cerita-6.png";

const SectionCeritaHeroes = () => {
  let cerita = [
    {
      image: cerita4,
      name: "Ibu Ina",
      role: "Cleansheet Heroes Bogor",
      message:
        "Saya senang bisa membantu anak-anak muda yang membutuhkan pekerjaan di sekitar saya. Sekaligus saya dapat memperkenalkan Cleansheet dengan visi misinya kepada orang-orang sekitar saya",
    },
    {
      image: cerita5,
      name: "Shahnaz Haque",
      role: "Public Figure",
      message:
        "Cleansheet heroes merupakan program gotong royong luar biasa dalam membantu anak-anak muda yg ingin mendapatkan pekerjaan, penghasilan sekaligus melanjutkan pendidikan. Selain itu, kita bisa membantu temen2 kita yg sedang kesusahan dalam urusan kebersihan",
    },
    {
      image: cerita6,
      name: "Ibu Somia",
      role: "Cleansheet Heroes",
      message:
        "Saya merupakan pensiunan PNS, dengan Cleansheet Heroes saya dapat menambah penghasilan dan kegiatan saya.",
    },
  ];
  return (
    <section id="cleansheet-partner-cerita-rangers" className="py-16 relative">
      <div className="bg-primaryDark h-1/4 md:h-1/3 lg:h-1/2 absolute top-0 left-0 right-0 -z-10"></div>
      <article className="max-w-container mx-auto px-6 md:px-4 flex flex-col gap-5">
        <div className="flex montserrat not-italic font-bold text-3xl leading-[140%] tracking-[0.005em] text-neutralLight">
          Cerita Cleansheet Heroes
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {cerita.map((cerita, index) => (
            <div
              className="flex flex-col gap-4 col-span-1 items-center p-6 bg-white drop-shadow-bottom rounded-3xl"
              key={"testimoni" + index}
            >
              <div className="flex flex-col justify-center items-center p-0 gap-2">
                <img src={cerita.image} alt={cerita.name} className="w-100" />
                <div className="flex flex-col justify-center items-center p-0">
                  <div className="not-italic font-bold text-base leading-[140%] tracking-[0.005em] text-dark">
                    {cerita.name}
                  </div>
                  <div className="not-italic font-normal text-sm leading-5 tracking-[0.005em] text-default">
                    {cerita.role}
                  </div>
                </div>
                <div>
                  <p className="not-italic font-normal text-base leading-[140%] tracking-[0.005em] text-default">
                    "{cerita.message}"
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </article>
    </section>
  );
};

export default SectionCeritaHeroes;
