import {ReactComponent as AppStore} from "../../../assets/vectors/icons/appstore.svg";
import {ReactComponent as PlayStore} from "../../../assets/vectors/icons/playstore.svg";
import hp from "../../../assets/images/misc/hp.png";
function SectionApp() {
  return (
    <section
      className="relative overflow-hidden bg-download-section-texture bg-cover"
      id="download-app"
    >
      <article className="pt-16 max-w-container mx-auto px-6 md:px-4">
        <div className="w-full grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-1 md:col-span-8 flex flex-col gap-7">
            <div className="flex sm:text-[22px] font-normal leading-[140%] tracking-wider">
              UNDUH APP
            </div>
            <div className="font-bold flex sm:text-[32px] leading-[140%] tracking-wider montserrat">
              Pesan Layanan Kebersihan Dalam Genggaman
            </div>
            <div className="text-dark font-normal flex sm:text-[22px] leading-[140%] tracking-wider">
              Mempermudah layanan dengan menghadirkan solusi aplikasi mobile
              untuk Anda. Pilih tombol di bawah, dan klik tombol download.
            </div>
            <div className="flex flex-row gap-4">
              <button>
                <PlayStore />
              </button>
              <button>
                <AppStore />
              </button>
            </div>
          </div>
          <div className="col-span-1 md:col-span-4 flex items-end">
            <img src={hp} alt="Phone Model" />
          </div>
        </div>
      </article>
    </section>
  );
}

export default SectionApp;
