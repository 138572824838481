import img from "../../../assets/images/brands/cleansheet.png";
import React from "react";
import { ReactComponent as General } from "../../../assets/vectors/icons/general-cleaning.svg";
import { ReactComponent as Deep } from "../../../assets/vectors/icons/deep-cleaning.svg";
import { ReactComponent as Toilet } from "../../../assets/vectors/icons/toilet-cleaning.svg";
import { ReactComponent as Furniture } from "../../../assets/vectors/icons/furniture-cleaning.svg";
import { ReactComponent as Hydro } from "../../../assets/vectors/icons/hydro-vaccum.svg";
import { ReactComponent as Poles } from "../../../assets/vectors/icons/poles-lantai.svg";
import { ReactComponent as Penyemprotan } from "../../../assets/vectors/icons/penyemprotan-disinfektan.svg";
import { ReactComponent as OneHome } from "../../../assets/vectors/icons/one-home-one-hope.svg";
import { ReactComponent as OneCorp } from "../../../assets/vectors/icons/one-corp-many-hope.svg";
import bgtop from "../../../assets/images/textures/bg-top.png";
import Button from "../../Button";
import { NavLink } from "react-router-dom";
import { SvgIcon } from "@mui/material";

function SectionCleansheet() {
    let services = [
        {
            title: "Deep Cleaning",
            desc: "Layanan pembersihan ruangan secara mendetail untuk kantor pasca renovasi atau pembersih periodik.",
        },
        {
            title: "Toilet Deep Cleaning",
            desc: "Layanan pembersihan spesialis pada sanitary (toilet, kloset, wastafel, urinoir) dari kerak dan noda membandel secara detail.",
        },
        {
            title: "Furniture Cleaning",
            desc: "Layanan pembersihan noda dan kotoran pada sofa kursi melalui bebeberapa langkah mulai dari spotting, washing, vacuuming dan drying untuk mencapai hasil maksimal sehingga sofa/kursi kembali fresh dan bersih.",
        },
        {
            title: "Hydro Vaccum",
            desc: "Layanan sedot tungau & debu pada perlengkapan kantor seperti karpet, sofa, kursi menggunakan vacuum hydrocleaner",
        },
        {
            title: "Floor Wash",
            desc: "Layanan pembersihan lantai dengan menggunakan mesin poles lantai (floor machine) dengan tujuan membuat lantai menjadi bersih.",
        },
        {
            title: "Carpet Cleaning",
            desc: "Layanan pembersihan karpet menggunakan alat dan bahan kimia khusus sehingga bebas dari debu, noda dan kotoran yang tersisa di dalam karpet",
        },
        {
            title: "Marble Polishing",
            desc: "Layanan pembersihan lantai marmer melalui proses kristalisasi dengan menggunakan alat dan bahan kimia khusus sehingga lantai menjadi bersih dan mengkilap.",
        },
        {
            title: "Outsourcing",
            desc: "Layanan jasa pembersihan kantor secara harian dengan sistem kontrak sehingga area kerja lobi, koridor, sanitary (toilet), pantry selalu bersih dan nyaman.",
        },
    ]

    return (
        <section className="w-full py-6 lg:py-16 relative" id="tentang-kami">
            <div className="md:max-w-container mx-auto px-6 md:px-4 grid grid-cols-1 lg:grid-cols-12 gap-4">
                <article className="col-span-1 lg:col-span-6 flex flex-col gap-5">
                    <h2 className="font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat">
                        <span className="underlined">Cleansheet Services</span>
                    </h2>
                    <div className="flex flex-col gap-5 text-dark text-[16px]">
                        <p>
                            Cleansheet Sebagai induk dari PT. Langkah Baik Indonesia (GSTEP Indonesia) adalah Startup Service Facility Professional yang berfokus pada cleaning service dan berbasis sebagai  Sociotechnopreneur yang memberdayakan anak anak muda potensial yang kurang mampu dengan tujuan untuk bisa melanjutkan pendidikannya ke jenjang lebih tinggi. Layanan kami terdiri dari layanan pembersihan untuk rumah, apartemen, kantor, kampus, institusi dan lainnya yang terintegrasi dengan sistem teknologi berbasis cloud yang terintegrasi untuk membantu memudahkan monitoring.
                        </p>
                    </div>
                </article>
                <aside className="col-span-1 lg:col-end-13 lg:col-span-5 flex justify-end">
                    <div className="center-web">
                        <img src={img} alt="" />
                    </div>
                </aside>
            </div>
            <div className="md:max-w-container mx-auto px-6 md:px-4 grid grid-cols-3 gap-8 py-16">
                {services.map((service, index) => (
                    <div className={`p-8 col-span-3 lg:col-span-1 rounded-[32px] gap-4 flex flex-col ${index % 2 === 0 ? 'bg-[#5798b9]' : 'bg-[#0f457b]'}`}>
                        <div className="flex items-center">
                            <div className="text-2xl text-white font-bold">
                                {service.title}
                            </div>
                        </div>
                        <div className="text-base text-white mt-2 mb-2">
                            {service.desc}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default SectionCleansheet;
