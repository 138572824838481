import React from "react";
import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import SectionAyoBergabung from "../../../components/LandingPage/JoinUsSection/SectionAyoBergabung";
import TabTitle from "../../../utils/TabTitle";

const JoinUsPage = () => {
  TabTitle("Join Us - Cleansheet");
  return (
    <>
      <SectionAyoBergabung />
      <FloatingWhatsapp />
    </>
  );
};

export default JoinUsPage;
