import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import SectionPartnerTestimoniPelanggan from "../../../components/LandingPage/HomeSection/SectionPartnerTestimoniPelanggan";
import SectionContactInfo from "../../../components/LandingPage/ServicesSection/Training/SectionContactInfo";
import SectionLayanan from "../../../components/LandingPage/ServicesSection/Training/SectionLayanan";
import TabTitle from "../../../utils/TabTitle";

const TrainingPage = () => {
    TabTitle("Training - Gstep Indonesia");
    return (
        <>
            <SectionLayanan />
            <SectionPartnerTestimoniPelanggan />
            <SectionContactInfo />
            <FloatingWhatsapp />
        </>
    );
}

export default TrainingPage;