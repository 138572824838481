import React from "react";
import {NavLink} from "react-router-dom";
import Button from "../../Button";
import testimoni from "../../../assets/images/misc/testimoni.png";

const SectionAyoBergabung = () => {
  return (
    <section id="ayo-bergabung-menjadi-partner-kami">
      <article className="max-w-container pt-16 pb-32 mx-auto px-6 md:px-4 lg:px-20 xl:px-48 flex flex-col gap-12">
        <h1 className="montserrat not-italic font-bold text-3xl leading-[140%] tracking-[0.005em] text-dark text-center">
          Ayo bergabung menjadi Partner Kami
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-14 lg:gap-20">
          <div className="flex flex-col gap-6 p-6 bg-white border border-primaryBright rounded-3xl col-span-1">
            <h2 className="montserrat not-italic font-bold text-2xl leading-[140%] tracking-[0.005em] text-dark text-center">
              <span className="text-primary">CleanSheet</span> Rangers
            </h2>
            <div>
              <img
                src={testimoni}
                alt="CleanSheet Rangers"
                className="w-full"
              />
            </div>
            <NavLink to={"/join-us/rangers"}>
              <Button customStyle=" w-full">
                <div className="w-full text-center not-italic font-bold text-sm lg:text-base leading-[140%] tracking-[0.005em] text-white py-1 px-3 md:px-5">
                  Daftar CleanSheet Rangers
                </div>
              </Button>
            </NavLink>
          </div>
          <div className="flex flex-col gap-6 p-6 bg-white border border-primaryBright rounded-3xl col-span-1">
            <h2 className="montserrat not-italic font-bold text-2xl leading-[140%] tracking-[0.005em] text-dark text-center">
              <span className="text-primary">CleanSheet</span> Heroes
            </h2>
            <div>
              <img src={testimoni} alt="CleanSheet Heroes" className="w-full" />
            </div>
            <NavLink to={"/join-us/heroes"}>
              <Button customStyle=" w-full">
                <div className="w-full text-center not-italic font-bold text-sm lg:text-base leading-[140%] tracking-[0.005em] text-white py-1 px-3 md:px-5">
                  Daftar CleanSheet Heroes
                </div>
              </Button>
            </NavLink>
          </div>
        </div>
      </article>
    </section>
  );
};

export default SectionAyoBergabung;
