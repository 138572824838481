import React from "react";
import Button from "../../Button";
import bergabungPartner from "../../../assets/images/misc/bergabung-partner.png";

const SectionTertarikBergabung = ({type, title, description, target}) => {
  return (
    <section id="tertarik-bergabung">
      <article className="max-w-container mx-auto px-6 md:px-4 pt-16 grid grid-cols-12">
        <div className="col-span-12 md:col-span-5 flex flex-col gap-4 pb-16">
          <h2 className="montserrat not-italic font-bold text-3xl text-dark leading-[140%] tracking-[0.005em]">
            {title}
          </h2>
          <p className="not-italic font-normal text-base leading-[140%] tracking-[0.005em] text-default">
            {description}
          </p>
          <div>
            <Button customStyle=" rounded-full">
              <div className="not-italic font-bold text-base leading-[140%] tracking-[0.005em] text-white px-5 py-1">
                Daftar {type} Sekarang
              </div>
            </Button>
          </div>
        </div>
        <div className="col-span-12 md:col-span-7 lg:col-end-13 lg:col-span-5 flex items-end">
          <img src={bergabungPartner} alt="bergabung-partner" />
        </div>
      </article>
    </section>
  );
};

export default SectionTertarikBergabung;
