import React from "react";
import {ReactComponent as General} from "../../../assets/vectors/icons/general-cleaning.svg";
import {ReactComponent as Deep} from "../../../assets/vectors/icons/deep-cleaning.svg";
import {ReactComponent as Toilet} from "../../../assets/vectors/icons/toilet-cleaning.svg";
import {ReactComponent as Furniture} from "../../../assets/vectors/icons/furniture-cleaning.svg";
import {ReactComponent as Hydro} from "../../../assets/vectors/icons/hydro-vaccum.svg";
import {ReactComponent as Poles} from "../../../assets/vectors/icons/poles-lantai.svg";
import {ReactComponent as Penyemprotan} from "../../../assets/vectors/icons/penyemprotan-disinfektan.svg";
import {ReactComponent as OneHome} from "../../../assets/vectors/icons/one-home-one-hope.svg";
import {ReactComponent as OneCorp} from "../../../assets/vectors/icons/one-corp-many-hope.svg";
import bgtop from "../../../assets/images/textures/bg-top.png";
import Button from "../../Button";
import {NavLink} from "react-router-dom";
import { SvgIcon } from "@mui/material";

function SectionServices() {
  let services = [
    {
      title: "General Cleaning",
      desc: "Layanan jasa pembersihan rumah & apartemen profesional yang meliputi pembersihan debu (dusting), pembersihan lantai, pembersihan langit-langit, hingga pembersihan seluruh permukaan furniture dalam ruangan",
      icon: General,
      link: "/services/general-cleaning",
    },
    {
      title: "Deep Cleaning",
      desc: "Layanan jasa pembersihan secara mendetail spesial untuk ruangan / rumah pasca renovasi, pasca banjir, dan telah lama ditinggalkan. Pembersihan meliputi pembersihan seluruh lantai, dinding, langit-langit, kaca",
      icon: Deep,
      link: "/services/deep-cleaning",
    },
    {
      title: "Toilet Cleaning",
      desc: "Layanan jasa membersihkan kerak membandel, bakteri, hingga kuman akan hilang dari kamar mandi",
      icon: Toilet,
      link: "/services/toilet-cleaning",
    },
    {
      title: "Furniture Cleaning",
      desc: "Layanan jasa laundry yang diperuntukkan kasur, sofa, karpet, dan kursi guna mengangkat noda, debu dan tungau secara maksimal",
      icon: Furniture,
      link: "/services/furniture-cleaning",
    },
    {
      title: "Hydro Vaccum",
      desc: "Layanan sedot tungau & debu yang diperuntukkan untuk kasur, sofa, karpet dan gorden",
      icon: Hydro,
      link: "/services/hydro-vaccum",
    },
    {
      title: "Poles Lantai",
      desc: "Layanan sedot tungau & debu yang diperuntukkan untuk kasur, sofa, karpet dan gordenLayanan jasa pembersihan rumah & apartemen profesional yang meliputi pembersihan debu (dusting), pembersihan lantai, pembersihan langit-langit, hingga pembersihan seluruh permukaan furniture dalam ruangan",
      icon: Poles,
      link: "/services/poles-lantai",
    },
    {
      title: "Penyemprotan Disinfektan",
      desc: "Layanan jasa penyemprotan dan sterilisasi rumah/kantor dengan menggunakan cairan disinfektan tersertifikasi kemenkes yang aman bagi keluarga dan orang-orang tersayang untuk membunuh kuman, bakteri dan virus",
      icon: Penyemprotan,
      link: "/services/penyemprotan-disinfektan",
    },
    {
      title: "One Home One Hope",
      desc: "Program “One Home One Hope” yaitu setiap satu rumah yang dibersihkan oleh Cleansheet, dapat memberikan harapan bagi satu anak muda untuk melanjutkan pendidikannya",
      icon: OneHome,
      link: "/services/one-home-one-hope",
    },
    {
      title: "One Corp Many Hope",
      desc: "Dalam program “One Corp Many Hope” yaitu setiap satu kantor yang dibersihkan oleh Cleansheet, dapat memberikan harapan bagi banyak anak muda untuk melanjutkan pendidikannya",
      icon: OneCorp,
      link: "/services/one-corp-many-hope",
    },
  ]
  return (
    <section className="py-16 bg-primaryLight" id="services">
      <article className="relative max-w-container mx-auto px-6 md:px-4">
        <img className="-z-10 absolute top-0 left-0" src={bgtop} alt="" />
        <h2 className="text-center font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat">
          Program layanan yang ditawarkan{" "}
          <span className="text-primary">Clean</span>Sheet
        </h2>
        <p className="text-center text-default text-base mt-[19px] mb-12">
          Prioritas kami adalah meyani Anda dengan sepenuh hari. Jangan lupa
          klik tombol subscribe dan share
        </p>
        <div className="grid grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div className="p-8 col-span-3 lg:col-span-1 bg-white rounded-[32px] gap-4 flex flex-col">
              <div className="flex items-center">
                <div className="bg-primary rounded-full p-2">
                  <div className="w-12 h-12 text-4xl text-white flex items-center justify-center">
                    <SvgIcon
                      component={service.icon}
                      inheritViewBox={true}
                      fontSize={"inherit"}
                    />
                  </div>
                </div>
                <div className="ml-4 text-2xl text-primaryDark font-bold">
                  {service.title}
                </div>
              </div>
              <div className="text-base text-default mt-4 mb-4">
                {service.desc}
              </div>
              <div className="mt-auto items-end">
                <NavLink to={service.link}>
                  <Button customStyle=" rounded-full">
                    <div className="px-2 text-white">Lihat Detail</div>
                  </Button>
                </NavLink>
              </div>
            </div>
          ))}
        </div>
      </article>
    </section>
  );
}

export default SectionServices;
