import TabTitle from "../../../utils/TabTitle";
import SectionServices from "../../../components/LandingPage/ServicesSection/SectionServices";
import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";

const ServicesPage = () => {
    TabTitle("Layanan - Cleansheet");
    return (
        <>
            <SectionServices />
            <FloatingWhatsapp />
        </>
    );
}

export default ServicesPage;