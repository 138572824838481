import React from "react";
import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import SectionBannerHeroes from "../../../components/LandingPage/JoinUsSection/SectionBannerHeroes";
import SectionCeritaHeroes from "../../../components/LandingPage/JoinUsSection/SectionCeritaHeroes";
import SectionKenapaHarusHeroes from "../../../components/LandingPage/JoinUsSection/SectionKenapaHarusHeroes";
import SectionTertarikBergabung from "../../../components/LandingPage/JoinUsSection/SectionTertarikBergabung";
import TabTitle from "../../../utils/TabTitle";

const Heroes = () => {
  TabTitle("Heroes - Cleansheet");
  return (
    <>
      <SectionBannerHeroes />
      <SectionKenapaHarusHeroes />
      <SectionCeritaHeroes />
      <SectionTertarikBergabung
        type={"Heroes"}
        title={
          <>
            Tertarik Bergabung Menjadi
            <span className="text-primary"> Cleansheet</span> Heroes?
          </>
        }
        description={
          "Ratusan Cleasnsheet Heroes Sudah Bergabung! Saatnya Kamu Bergabung Dan Berkontribusi Bersama Untuk Indonesia Yang Lebih Baik"
        }
      />
      <FloatingWhatsapp />
    </>
  );
};

export default Heroes;
