import img from "../../../../assets/images/misc/outsourcing.png";

function SectionLayanan() {
    return (
        <section className="py-16" id="sectionProblems">
            <article className="relative max-w-container mx-auto px-6 md:px-4">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    <div className="center-web">
                        <img src={img} alt="" className="object-cover" />
                    </div>
                    <div className='self-start'>
                        <h2 className="font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat">
                            <span className="underlined">Outsourcing / HR Management</span>
                        </h2>
                        <p className='text-base text-dark text-[16px] mt-4'>Layanan Pengelolaan sumber daya manusia yang efekfif dan efisien serta terintegrasi dengan strategi bisnis perusahaan.
                        </p>
                    </div>
                </div>
                <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 px-8 lg:mx-16 py-16">
                    <div className="md:flex justify-between">
                        <div
                            className="flex flex-col lg:flex justify-between gap-4 lg:gap-4 mb-6 text-dark"
                        >
                            <div className="flex items-start text-base font-bold">Front Office Staff, Leader, Supervisor</div>
                            <div className="flex items-start">Receptionist Staff</div>
                            <div className="flex items-start">Customer Service Staff</div>
                            <div className="flex items-start">Teller</div>
                            <div className="flex items-start">Call Center</div>
                            <div className="flex items-start">Telephone Operator</div>
                            <div className="flex items-start">Public Relation</div>
                        </div>
                    </div>
                    <div className="md:flex justify-between">
                        <div
                            className="flex flex-col lg:flex justify-between gap-4 lg:gap-4 mb-6"
                        >
                            <div className="flex items-start text-base font-bold">Back Office Staff, Leader, Supervisor</div>
                            <div className="flex items-start">Admin Staff</div>
                            <div className="flex items-start">Data Entry</div>
                            <div className="flex items-start">Marketing Corporate</div>
                            <div className="flex items-start">Marketing Support</div>
                            <div className="flex items-start">IT Staff</div>
                            <div className="flex items-start">Accounting/Finance Staff</div>
                            <div className="flex items-start">Cashier</div>

                        </div>
                    </div>
                    <div className="md:flex justify-between">
                        <div
                            className="flex flex-col lg:flex gap-4 lg:gap-4 mb-6"
                        >
                            <div className="flex items-start text-base font-bold">Office & Building Staff, Leader, Supervisor</div>
                            <div className="flex items-start">Office Boy / Office Girl</div>
                            <div className="flex items-start">House Keeping</div>
                            <div className="flex items-start">Messenger</div>
                            <div className="flex items-start">Mechanical & Engineering</div>
                            <div className="flex items-start">Cleaning Service</div>
                        </div>
                    </div>
                    <div className="md:flex justify-between">
                        <div
                            className="flex flex-col lg:flex gap-4 lg:gap-4 mb-6"
                        >
                            <div className="flex items-start text-base font-bold">Operational Staff, Leader, Supervisor</div>
                            <div className="flex items-start">Driver Operational</div>
                            <div className="flex items-start">Collector</div>
                            <div className="flex items-start">Security</div>
                            <div className="flex items-start">Waitress / Resto Crew</div>
                            <div className="flex items-start">Food & Beverage (Chef)</div>
                            <div className="flex items-start">Sales Promotion Girl/ Boy</div>
                        </div>
                    </div>
                </div>
            </article>
        </section>
    );
}

export default SectionLayanan;