import ipb from "../../../assets/images/brands/ipb.webp";
import kemendikbud from "../../../assets/images/brands/kemendikbud.webp";
import kemenpora from "../../../assets/images/brands/kemenpora.webp";
import rctiplus from "../../../assets/images/brands/rctiplus.webp";
import sindonews from "../../../assets/images/brands/sindonews.webp";
import tvri from "../../../assets/images/brands/tvri.webp";
import {Swiper, SwiperSlide} from "swiper/react";
import {Mousewheel} from "swiper";
function SectionMedia() {
  return (
    <section id="liputan-media" className="py-16">
      <article className="max-w-container mx-auto px-6 md:px-4 flex flex-col gap-5">
        <div className="font-bold flex justify-center text-[32px] leading-[140%] tracking-wider montserrat">
        Media Coverage
        </div>
      </article>
    </section>
  );
}

export default SectionMedia;
