import img from "../../../assets/images/misc/img_about.jpg";

function SectionAbout() {
  return (
    <section className="w-full py-6 lg:py-16 relative" id="tentang-kami">
      <div className="md:max-w-container mx-auto px-6 md:px-4 grid grid-cols-1 lg:grid-cols-12 gap-4">
        <article className="col-span-1 lg:col-span-6 flex flex-col gap-5">
          <h2 className="font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat">
            <span className="underlined">Tentang Kami</span>
          </h2>
          <div className="flex flex-col gap-5 text-dark text-[16px]">
            <p>
              Tentang
              Perusahaan Jasa pelayanan yang berfokus meningkatkan dan mengembangkan skill sumber daya manusia untuk semakin terlatih dan terpercaya menjadi bagian dari unit bisnis sesuai dengan perkembangan dinamika dunia industri
            </p>
            <p>
              <h4 className="font-bold text-[24px] leading-[130%] tracking-wider text-dark text-[16px]">
                <span className="underlined">Visi</span>
              </h4>
              Menjadi mitra terpercaya bagi perusahaan dan individu dalam membangun masa depan yang lebih baik melalui pengembangan sumber daya manusia, penyaluran dan pemberdayaan tenaga kerja yang berkualitas guna mendorong keberlanjutan di setiap aspek bisnis.
            </p>
            <p>
              <h4 className="font-bold text-[24px] leading-[130%] tracking-wider text-dark">
                <span className="underlined">Misi</span>
              </h4>
              <ul className="list-inside text-dark mt-2">
                <li className="flex flex-col mb-2 "> {/* Added margin-bottom for gap */}
                  <div className="flex items-center">
                    <span className="mr-2">✔️</span>
                    <strong>Pengembangan Sumber Daya Manusia</strong>
                  </div>
                </li>
                <li className="flex flex-col mb-2"> {/* Added margin-bottom for gap */}
                  <div className="flex items-center">
                    <span className="mr-2">✔️</span>
                    <strong>Penyaluran dan Penyediaan Tenaga Kerja</strong>
                  </div>
                </li>
                <li className="flex flex-col mb-2"> {/* Added margin-bottom for gap */}
                  <div className="flex items-center">
                    <span className="mr-2">✔️</span>
                    <strong>Konsultasi Manajemen dan CSR</strong>
                  </div>
                </li>
                <li className="flex flex-col mb-2"> {/* Added margin-bottom for gap */}
                  <div className="flex items-center">
                    <span className="mr-2">✔️</span>
                    <strong>Kemitraan dan Kolaborasi</strong>
                  </div>
                </li>
              </ul>
            </p>
          </div>
        </article>
        <aside className="col-span-1 lg:col-end-13 lg:col-span-5 flex justify-end">
          <div className="center-web">
            <img src={img} alt="" />
          </div>
        </aside>
      </div>
    </section>
  );
}

export default SectionAbout;
