import news from '../../../assets/images/banner/news.png';

function SectionTodayNews() {
    return (
        <section className="py-16" id="headline">
            <article className="relative max-w-container mx-auto px-6 md:px-4">
                <div className="text-[22px] font-bold">Sorotan Hari Ini</div>
                <div className="mt-6 grid grid-rows-2 gap-12">
                    <div className="grid grid-cols-3 gap-12">
                        <div>
                            <img className="max-h-[178px] w-full object-cover" src={news} alt="" />
                            <div className="mt-6 text-default text-xs">26 Juli 2022</div>
                            <div className="text-xl monserrat font-bold text-dark mt-1 mb-1">Fogging Asap dan Embun, Mana yang Lebih Aman?</div>
                            <p className="text-limit text-default text-sm ">Fogging bukan merupakan cara mencegah perkembangbiakan nyamuk disekitar kita, atau mensterilkan ruangan dengan desinfektan. Selain itu, fogging nyamuk hanya mematikan nyamuk dewasa saja, sedangkan telur dan jentik nyamuk, tidak akan mati dengan fogging.</p>
                        </div>
                        <div>
                            <img className="max-h-[178px] w-full object-cover" src={news} alt="" />
                            <div className="mt-6 text-default text-xs">26 Juli 2022</div>
                            <div className="text-xl monserrat font-bold text-dark mt-1 mb-1">Fogging Asap dan Embun, Mana yang Lebih Aman?</div>
                            <p className="text-limit text-default text-sm ">Fogging bukan merupakan cara mencegah perkembangbiakan nyamuk disekitar kita, atau mensterilkan ruangan dengan desinfektan. Selain itu, fogging nyamuk hanya mematikan nyamuk dewasa saja, sedangkan telur dan jentik nyamuk, tidak akan mati dengan fogging.</p>
                        </div>
                        <div>
                            <img className="max-h-[178px] w-full object-cover" src={news} alt="" />
                            <div className="mt-6 text-default text-xs">26 Juli 2022</div>
                            <div className="text-xl monserrat font-bold text-dark mt-1 mb-1">Fogging Asap dan Embun, Mana yang Lebih Aman?</div>
                            <p className="text-limit text-default text-sm ">Fogging bukan merupakan cara mencegah perkembangbiakan nyamuk disekitar kita, atau mensterilkan ruangan dengan desinfektan. Selain itu, fogging nyamuk hanya mematikan nyamuk dewasa saja, sedangkan telur dan jentik nyamuk, tidak akan mati dengan fogging.</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-3 gap-12">
                        <div>
                            <img className="max-h-[178px] w-full object-cover" src={news} alt="" />
                            <div className="mt-6 text-default text-xs">26 Juli 2022</div>
                            <div className="text-xl monserrat font-bold text-dark mt-1 mb-1">Fogging Asap dan Embun, Mana yang Lebih Aman?</div>
                            <p className="text-limit text-default text-sm ">Fogging bukan merupakan cara mencegah perkembangbiakan nyamuk disekitar kita, atau mensterilkan ruangan dengan desinfektan. Selain itu, fogging nyamuk hanya mematikan nyamuk dewasa saja, sedangkan telur dan jentik nyamuk, tidak akan mati dengan fogging.</p>
                        </div>
                        <div>
                            <img className="max-h-[178px] w-full object-cover" src={news} alt="" />
                            <div className="mt-6 text-default text-xs">26 Juli 2022</div>
                            <div className="text-xl monserrat font-bold text-dark mt-1 mb-1">Fogging Asap dan Embun, Mana yang Lebih Aman?</div>
                            <p className="text-limit text-default text-sm ">Fogging bukan merupakan cara mencegah perkembangbiakan nyamuk disekitar kita, atau mensterilkan ruangan dengan desinfektan. Selain itu, fogging nyamuk hanya mematikan nyamuk dewasa saja, sedangkan telur dan jentik nyamuk, tidak akan mati dengan fogging.</p>
                        </div>
                        <div>
                            <img className="max-h-[178px] w-full object-cover" src={news} alt="" />
                            <div className="mt-6 text-default text-xs">26 Juli 2022</div>
                            <div className="text-xl monserrat font-bold text-dark mt-1 mb-1">Fogging Asap dan Embun, Mana yang Lebih Aman?</div>
                            <p className="text-limit text-default text-sm ">Fogging bukan merupakan cara mencegah perkembangbiakan nyamuk disekitar kita, atau mensterilkan ruangan dengan desinfektan. Selain itu, fogging nyamuk hanya mematikan nyamuk dewasa saja, sedangkan telur dan jentik nyamuk, tidak akan mati dengan fogging.</p>
                        </div>
                    </div>
                </div>
            </article>
        </section>

    );
}

export default SectionTodayNews;