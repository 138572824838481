import { Menu, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { NavLink } from 'react-router-dom';
import Icon from "@mdi/react";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";

function Dropdown({ label, items, isMobile, onItemClick }) {
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleSubMenu = (index) => setOpenSubMenu(openSubMenu === index ? null : index);

  // Toggle main dropdown menu on hover
  const handleMouseEnter = () => setIsMenuOpen(true);
  const handleMouseLeave = () => {
    setIsMenuOpen(false);
    setOpenSubMenu(null); // close any open submenu when main menu closes
  };

  if (isMobile) {
    return (
      <div className="w-full">
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="flex items-center justify-between w-full px-4 py-2 text-sm font-bold leading-[140%] tracking-wider text-dark"
        >
          {label}
          <Icon path={mdiChevronDown} size={1} className="text-dark" />
        </button>
        {isMenuOpen && (
          <div className="mt-2 space-y-2 pl-4">
            {items.map((item, index) => (
              <div key={index}>
                <button
                  onClick={() => {
                    item.subItems ? toggleSubMenu(index) : onItemClick(item);
                  }}
                  className="flex items-center justify-between w-full px-4 py-2 text-left text-sm font-bold leading-[140%] tracking-wider text-dark"
                >
                  {item.title}
                  {item.subItems && (
                    <Icon
                      path={openSubMenu === index ? mdiChevronUp : mdiChevronDown}
                      size={0.7}
                      className="text-dark ml-auto"
                    />
                  )}
                </button>
                {openSubMenu === index && item.subItems && (
                  <div className="pl-4 space-y-2">
                    {item.subItems.map((subItem, subIndex) => (
                      <NavLink
                        key={subIndex}
                        to={`/services/${subItem.path}`}
                        onClick={() => onItemClick(subItem)}
                        className="block px-4 py-2 text-left text-sm text-dark"
                      >
                        {subItem.title}
                      </NavLink>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <Menu.Button className="flex items-center justify-between w-full px-6 py-3 text-left text-sm font-bold leading-[140%] tracking-wider text-dark focus:outline-none">
              {label}
              <Icon path={mdiChevronDown} size={1} className="text-dark ml-auto" />
            </Menu.Button>
            <Transition
              show={isMenuOpen}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items static className="absolute left-0 z-50 w-60 mt-6 origin-top-left bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {items.map((item, index) => (
                    <div key={index}>
                      <button
                        onClick={() => {
                          if (item.subItems) {
                            toggleSubMenu(index);
                          } else {
                            onItemClick(item);
                          }
                        }}
                        className="flex items-center justify-between w-full px-8 py-4 text-left text-sm font-bold leading-[140%] tracking-wider text-dark hover:bg-primary hover:text-white"
                      >
                        {item.title}
                        {item.subItems && (
                          <Icon
                            path={openSubMenu === index ? mdiChevronUp : mdiChevronDown}
                            size={0.7}
                            className="ml-auto text-dark"
                          />
                        )}
                      </button>
                      {openSubMenu === index && item.subItems && (
                        <div className="pl-8 space-y-2">
                          {item.subItems.map((subItem, subIndex) => (
                            <NavLink
                              key={subIndex}
                              to={`/services/${subItem.path}`}
                              onClick={() => onItemClick(subItem)}
                              className="block px-8 py-4 text-left text-sm font-medium text-dark hover:bg-primary hover:text-white"
                            >
                              {subItem.title}
                            </NavLink>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </div>
        </>
      )}
    </Menu>
  );
}

export default Dropdown;
