import React from "react";
import price from "../../../assets/images/icons/price.png";
import plant from "../../../assets/images/icons/plant.png";
import cleaning from "../../../assets/images/icons/cleaning.png";
import rating from "../../../assets/images/icons/rating.png";
import team from "../../../assets/images/icons/team.png";

const SectionKenapaHarusHeroes = () => {
  let data = [
    {
      image: price,
      label: "Mendapatkan Penghasilan",
    },
    {
      image: plant,
      label: "Mendapatkan Kesempatan Beasiswa",
    },
    {
      image: cleaning,
      label: "Mendapatkan Berbagai Layanan Cleansheet",
    },
    {
      image: rating,
      label: "Dapatkan Relasi dengan CS Rangers & VS Customers se-Jabodetabek",
    },
    {
      image: team,
      label: "Ratusan Mitra Sudah Bergabung",
    },
  ];

  return (
    <section id="kenapa-harus-heroes">
      <article className="max-w-container mx-auto px-6 md:px-4 flex flex-col gap-8 pt-16 pb-5">
        <h2 className="montserrat not-italic font-bold text-3xl leading-[140%] tracking-[0.005em] text-dark">
          Apa Itu <span className="text-primary">Cleansheet</span> Heroes?
        </h2>
        <p className="not-italic font-normal text-base leading-[140%] tracking-[0.005em] text-default">
          Cleansheet tidak bisa sendiri untuk mewujudkan cita-cita membantu
          ribuan anak untuk bisa melanjutkan pendidikannya, kami butuh
          kolaborasi dan kontribusi dari Cleansheet's Heroes. Cleansheet's
          Heroes merupakan partner dalam merekrut CS Rangers (anak-anak muda
          yang membutuhkan pekerjaan dan ingin melanjutkan pendidikan) dan
          memasarkan maupun menyebarkan informasi tentang Cleansheet ke banyak
          orang khususnya lingkungan terdekatnya. Mari berkontribusi bersama
          Cleansheet untuk masa depan anak muda Indonesia yang lebih gemilang.
        </p>
      </article>
      <article className="max-w-container mx-auto px-6 md:px-4 flex flex-col gap-8 pt-5 pb-16">
        <h2 className="montserrat not-italic font-bold text-3xl leading-[140%] tracking-[0.005em] text-dark">
          Kenapa Harus Bergabung Dengan{" "}
          <span className="text-primary">Cleansheet</span> Heroes
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-10 gap-y-4">
          {data.map((item, index) => (
            <div
              className="col-span-1 flex flex-row items-center gap-4"
              key={index}
            >
              <img src={item.image} alt={index} className="w-12 h-12" />
              <div className="not-italic font-bold text-base leading-[140%] tracking-[0.005em] text-dark">
                {item.label}
              </div>
            </div>
          ))}
        </div>
      </article>
    </section>
  );
};

export default SectionKenapaHarusHeroes;
