import img from "../../../../assets/images/misc/banner-3.jpg";

function SectionLayanan() {
    return (
        <section className="py-16 relative" id="foundation">
            <div className="md:max-w-container mx-auto px-6 md:px-4 grid grid-cols-1 lg:grid-cols-12 gap-4">
                <article className="col-span-1 lg:col-span-6 flex flex-col gap-5">
                    <h2 className="font-bold text-[32px] leading-[130%] tracking-wider text-dark">
                        <span className="underlined"><span className="text-primary">Training</span></span>
                    </h2>
                    <div className="mb-6">
                        <div className="flex text-dark text-[16px]">
                            Kami GStep Indonesia adalah perusahaan yang bergerak di bidang Academy & HR Provider. Kami mengajak
                            lembaga, organisasi dan institusi Anda untuk bergabung dan menjadi partner belajar dalam program pelatihan
                            kami yang bertujuan untuk meningkatkan keterampilan dan pengetahuan bagi anda untuk mencapai tujuan
                            belajar dan produktivitas
                        </div>
                        <div className="mt-5">
                            <h4 className="font-bold text-[20px] leading-[130%] tracking-wider text-dark">
                                <span className="underlined">Apa yang bisa kita Tawarkan</span>
                            </h4>
                            <ul className="list-inside text-dark mt-4">
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        Pelatihan komprehensif sesuai bidang dan kebutuhan anda
                                    </div>
                                </li>
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        Pengajar berpengalaman dan berkompeten
                                    </div>
                                </li>
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        Metode pembelajaran praktis dan aplikatif
                                    </div>
                                </li>
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        Sertifikasi setelah selesai pelatihan
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="mt-5">
                            <h4 className="font-bold text-[20px] leading-[130%] tracking-wider text-dark">
                                <span className="underlined">Mengapa Anda Memilih Kami ?</span>
                            </h4>
                            <ul className="list-inside text-dark mt-4">
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        Partner belajar yang menemani pertumbuhan dan perkembangan setiap prosesnya
                                    </div>
                                </li>
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        Meningkatkan citra lembaga, organisasi dan institusi dalam pembelajaran
                                    </div>
                                </li>
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        Memenuhi ekspektasi sesuai harapan mitra
                                    </div>
                                </li>
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        Fleksibel dan menyesuaikan dengan waktu dan kebutuhan anda
                                    </div>
                                </li>
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        Menggunakan metode pembelajaran terkini sehingga mudah diterima
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="mt-5">
                            <h4 className="font-bold text-[20px] leading-[130%] tracking-wider text-dark">
                                <span className="underlined">Beberapa Materi Training yang kami tawarkan :</span>
                            </h4>
                            <ul className="list-inside text-dark mt-4">
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        Service Excellent
                                    </div>
                                </li>
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        Networking Skill
                                    </div>
                                </li>
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        Complaint Handling
                                    </div>
                                </li>
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        Time Management
                                    </div>
                                </li>
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        Sales & Marketing Operation
                                    </div>
                                </li>
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        Human Capital Operation
                                    </div>
                                </li>
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        Cust. Service and Cust. Relation Operation 
                                    </div>
                                </li>
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        Product Development
                                    </div>
                                </li>
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        Business Operation
                                    </div>
                                </li>
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        Restaurant Budget Forecast
                                    </div>
                                </li>
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        Leadership Development
                                    </div>
                                </li>
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        Masa Persiapan Pensiun
                                    </div>
                                </li>
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        Quality Assurance Operation
                                    </div>
                                </li>
                                <li className="flex flex-col mb-2">
                                    <div className="flex items-center">
                                        <span className="mr-2">✔️</span>
                                        Training Program Lainnya
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </article>
                <aside className="col-span-1 lg:col-end-13 lg:col-span-5 flex justify-end">
                    <div className="center-web">
                        <img src={img} alt="" />
                    </div>
                </aside>
            </div>
        </section>

    );
}

export default SectionLayanan;